import React from "react"
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent"

const Cookies = () => {
  return (
    <CookieConsent
      style={{
        background: "white",
        alignItems: "center",
        flexWrap: "wrap",
        fontSize: 14,
        color: "black",
        boxShadow: "0px 0 10px rgba(0, 0, 0, 0.15)",
      }}
      buttonStyle={{
        background: "#FFADAD",
        color: "#000",
        padding: "8px 24px",
        fontWeight: 500,
        border: "solid 1px white",
      }}
      buttonText="Sprejmi"
      cookieName="viewed_cookie_policy"
      ariaAcceptLabel="Sprejmi piškotke"
    >
      To spletno mesto za svoje delovanje uporablja spletne piškotke, ki ne
      hranijo osebnih podatkov. Z nadaljno uporabo spletnega mesta se strinjate
      z mojimi spletnimi piškotki.{" "}
      <Link to="/terms-of-use" className="underline text-blue-600 font-medium">
        Preberi več
      </Link>
    </CookieConsent>
  )
}

export default Cookies
